<template>
  <div class="item">
    <button
      ref="button"
      type="button"
      class="button"
      @click="emit('toggle', itemId)"
    >
      <span class="label">&nbsp;</span>
      <div class="wrap">
        <ButtonGhost
          class="filterOpener"
          :content="{
            text: t('components.pageheader.search.item.filter.button.title'),
            icon: 'ion:funnel-outline',
          }"
        />
        <span v-if="count !== 0" class="count">{{ count }}</span>
      </div>
    </button>

    <PageheaderSearchItemTab
      ref="tab"
      :content="{
        icon: 'ion:funnel-outline',
        text: t('components.pageheader.search.item.filter.button.title'),
      }"
      :value="count"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-focus-first
      v-click-outside="{
        ignore: [button, tab],
        handler: () => emit('deactivate', itemId),
      }"
      @keyup.esc="emit('deactivate', itemId)"
      @deactivate="emit('deactivate', itemId)"
    >
      <PageheaderSearchItemDropdownFilterEvent
        v-if="whlModuleType === WhlModuleType.Event"
        v-model="model"
        v-model:date="date"
        @apply="emit('apply', itemId)"
      />

      <PageheaderSearchItemDropdownFilterTour
        v-if="whlModuleType === WhlModuleType.Tour"
        v-model="model"
        @apply="emit('apply', itemId)"
      />

      <PageheaderSearchItemDropdownFilterPoi
        v-if="whlModuleType === WhlModuleType.Poi"
        v-model="model"
        v-model:date="date"
        @apply="emit('apply', itemId)"
      />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
import { MediaType } from '../../../../assets/scss/variables';
import { WhlModuleType } from '@models/WhlModuleType';
import type { DateFilter, FilterModel } from '../models';

const { t } = useI18n();
const searchStore = useSearchStore();
const globalStore = useGlobalStore();

const button = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const emit = defineEmits([
  'activate',
  'deactivate',
  'toggle',
  'apply',
  'close',
]);

const model = defineModel<FilterModel>();
const date = defineModel<DateFilter>('date');

const props = defineProps<{
  itemId: string;
  activeItem: string;
  whlModuleType: WhlModuleType;
}>();

const count: Ref<number> = computed(() => {
  let count = 0;

  let dateCounted = false;
  let locationCounted = false;

  Object.entries(searchStore.state).forEach(([key, value]) => {
    const isDateKey =
      key === 'dateFrom' || key === 'dateTo' || key === 'daytime';
    const isLocationKey =
      key === 'locationId' ||
      key === 'locationType' ||
      key === 'locationName' ||
      key === 'latitude' ||
      key === 'longitude';
    const isFeatureKey = key === 'hasSingleEvent' || key === 'onlyFree';

    const isExcludedEventDate =
      props.whlModuleType === WhlModuleType.Event && isDateKey;

    if (key === 'search' || isExcludedEventDate) {
      return;
    }

    if (isDateKey) {
      if (
        !dateCounted &&
        !isEmpty([searchStore.state.dateFrom, searchStore.state.dateTo], 'some')
      ) {
        count++;
        dateCounted = true;
      }
      return;
    }

    if (isLocationKey) {
      if (
        (!isEmpty(
          [
            searchStore.state.locationId,
            searchStore.state.locationType,
            searchStore.state.locationName,
          ],
          'some'
        ) ||
          !isEmpty(
            [
              searchStore.state.longitude,
              searchStore.state.latitude,
              searchStore.state.locationName,
            ],
            'some'
          )) &&
        !locationCounted
      ) {
        count++;
        locationCounted = true;
      }
      return;
    }

    if (isFeatureKey) {
      if (!isEmpty(value)) {
        if (globalStore.state.mediaType === MediaType.TY) {
          count++;
        }
      }
      return;
    }

    if (Array.isArray(value)) {
      count += value.filter((item) => !isEmpty(item)).length;
    } else if (!isEmpty(value)) {
      count++;
    }
  });

  return count;
});

const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('activate', props.itemId);
  }
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
