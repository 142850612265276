<template>
  <footer class="pageFooter">
    <div class="grid">
      <PageFooterContact
        v-if="address || metaNav"
        :content="{
          address: address,
          metaNav: metaNav,
          languageOptions: languageOptions,
        }"
        :hide-logo="hideLogo"
      />

      <PageFooterAddEvent
        v-if="content.addRecord"
        :content="content.addRecord"
      />
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { Content, Config } from './models';

const props = defineProps<{
  content: Content;
  config: Config;
}>();

const address = computed(() => props.content.address);
const metaNav = computed(() => props.content.metaNav);
const languageOptions = computed(() => props.content.languageOptions);
const hideLogo = computed(() => props.config.hide.logo);
</script>

<style src="./PageFooter.scss" scoped lang="scss"></style>
