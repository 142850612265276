<template>
  <div class="filter">
    <ArticleAccordion>
      <!-- LOCATION FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.locationFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-tour.location.text'
          )
        "
      >
        <div class="content">
          <PageheaderSearchItemDropdownFilterDistance
            v-model="selectedItems.location.location"
          />

          <!-- <Input-CheckboxList
            label="Verkehrsinfrastruktur (im Umkreis von 2km)"
            :items="infrastructures"
            name="infrastructures"
          /> -->
        </div>
      </ArticleAccordionItem>

      <!-- CATEGORY FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.categoryFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-tour.categories.text'
          )
        "
      >
        <div class="content horizontalPadding">
          <ArticleAccordion>
            <template v-for="(items, key, index) in activities" :key="index">
              <ArticleAccordionItem
                v-if="key !== emptyGroupName"
                :text="key"
                :small="true"
              >
                <InputCheckboxList
                  v-model="selectedItems.activities"
                  :items="activities"
                  name="key"
                />
              </ArticleAccordionItem>

              <InputCheckboxList
                v-else
                v-model="selectedItems.activities"
                :items="items"
                name="key"
              />
            </template>
          </ArticleAccordion>
        </div>
      </ArticleAccordionItem>

      <!-- DIFFICULTY FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.difficultyLevelFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-tour.difficulties.text'
          )
        "
      >
        <InputPickerMulti
          v-model="selectedItems.difficulties"
          :items="difficulties"
          name="difficulties"
        />
      </ArticleAccordionItem>

      <!-- LENGTH & DURATION FILTER -->
      <ArticleAccordionItem
        v-if="true || widgetConfigTypeDef.lengthDurationFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-tour.lengthDuration.text'
          )
        "
      >
        <div class="content">
          <InputRange
            v-model="selectedItems.length"
            :label="t('pageheader.search.item.dropdown.filter.tour.length')"
            :min="lengthMin"
            :max="lengthMax"
            :display-fn="formatMtoKm"
          />

          <InputRange
            v-model="selectedItems.duration"
            :label="t('pageheader.search.item.dropdown.filter.tour.duration')"
            :min="durationMin"
            :max="durationMax"
            :display-fn="formatDuration"
          />
        </div>
      </ArticleAccordionItem>

      <!-- ATTRIBUTE FILTER -->
      <!--       <Article-Accordion-Item
        v-if="widgetConfigTypeDef.categoryFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-tour.attributes.text'
          )
        "
      >
        <Input-Picker-Multi
          v-model="selectedItems.attributes"
          :items="attributes"
          name="attributes"
        />
      </Article-Accordion-Item> -->
    </ArticleAccordion>

    <PageheaderSearchItemDropdownFilterFooter
      :content="
        t(
          'components.pageheader.search.item.dropdown.filter.filter-tour.footer',
          { count: totalCount }
        )
      "
      @apply="emit('apply')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Category, WidgetConfigTourDef } from '@gql/schema';
import { TourFilterProperty } from '@models/BaseFilterInput';
import type { Nullable } from '@models/CustomUtilityTypes';
import type { FilterModel } from '../../../models';
import type { LocationType } from './Distance/models';
import { formatDuration } from '@utils/formatters/formatDuration';
const { t } = useI18n();
const formatMtoKm = (val: number) => formatDistanceToKm(val, 'm', 0);

const emit = defineEmits(['apply']);

const model = defineModel<FilterModel>();

const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(
  widgetConfig
) as Ref<WidgetConfigTourDef>;

const searchStore = useSearchStore();

const searchStateFromCurrentFormData = mapFilterToSearchModel(
  () => searchStore.state.search.join(' '),
  {
    date: {
      selectedDateRange: [searchStore.state.dateFrom, searchStore.state.dateTo],
      pendingDate: null,
    },
    additional: {
      categories: [],
      dayTime: [],
    },
  },
  model
);

const { totalCount, categoryFacets } = fetchTourFilterFacets(
  widgetConfig,
  computed(
    () =>
      toValue(buildTourFilter(widgetConfig, searchStateFromCurrentFormData))
        ?.filter
  ),
  computed(
    () =>
      toValue(
        buildTourFilter(widgetConfig, searchStateFromCurrentFormData, [
          TourFilterProperty.CATEGORY,
        ])
      )?.filter
  ),
  computed(
    () =>
      toValue(
        buildTourFilter(widgetConfig, searchStateFromCurrentFormData, [
          TourFilterProperty.ATTRIBUTE,
        ])
      )?.filter
  ),
  toValue(widgetConfigTypeDef)?.activityFilterTourCategories?.map((_) => _.id),
  toValue(widgetConfigTypeDef)?.attributeFilterTourAttributes?.map((_) => _.id)
);

const selectedItems = ref<{
  activities: number[];
  attributes: number[];
  location: {
    infrastructures: number[];
    location: {
      id: number | undefined;
      type: LocationType | undefined;
      name: string | undefined;
      latitude: number | undefined;
      longitude: number | undefined;
    };
  };
  length: [number, number];
  duration: [number, number];
  difficulties: number[];
  particularities: number[];
}>({
  activities: [],
  attributes: [],
  location: {
    infrastructures: [],
    location: {
      id: undefined,
      type: undefined,
      name: undefined,
      latitude: undefined,
      longitude: undefined,
    },
  },
  length: [0, 0],
  duration: [0, 0],
  difficulties: [],
  particularities: [],
});

const activities = computed(() =>
  groupCategoriesByParent(model.value?.categories, toValue(categoryFacets))
);

const difficulties = [
  { label: t('difficulty.1'), value: '1' },
  { label: t('difficulty.2'), value: '2' },
  { label: t('difficulty.3'), value: '3' },
];

// Watch for changes in the model and update selectedItems accordingly (model -> selectedItems)
watch(
  model,
  (newValue) => {
    if (!newValue) {
      return;
    }

    selectedItems.value.activities = newValue.categories
      .filter((category) => category.id && category.selected)
      .map((category) => category.id!);
    selectedItems.value.attributes = newValue.attributes
      .filter((attribute) => attribute.id && attribute.selected)
      .map((attribute) => attribute.id!);
    selectedItems.value.length = newValue.tour.length || [0, 0];
    selectedItems.value.duration = newValue.tour.duration || [0, 0];
    selectedItems.value.difficulties = newValue.tour.difficulties || [];
  },
  { deep: true, immediate: true }
);

// Watch for changes in selectedItems and update the model accordingly (selectedItems -> model)
watch(
  selectedItems,
  (newValue) => {
    if (!model.value) {
      return;
    }

    model.value.categories?.forEach((category) => {
      category.selected = newValue.activities.includes(category.id);
    });
    model.value.attributes?.forEach((attribute) => {
      attribute.selected = newValue.attributes.includes(attribute.id);
    });

    if (newValue.length[0] !== 0 || newValue.length[1] !== toValue(lengthMax)) {
      model.value.tour.length = newValue.length;
    } else {
      model.value.tour.length = undefined;
    }
    if (
      newValue.duration[0] !== 0 ||
      newValue.duration[1] !== toValue(durationMax)
    ) {
      model.value.tour.duration = newValue.duration;
    } else {
      model.value.tour.duration = undefined;
    }
    model.value.tour.difficulties = newValue.difficulties;
    model.value.location.location.id = newValue.location.location.id;
    model.value.location.location.type = newValue.location.location.type;
    model.value.location.location.name = newValue.location.location.name;
    model.value.location.location.latitude =
      newValue.location.location.latitude;
    model.value.location.location.longitude =
      newValue.location.location.longitude;
  },
  { deep: true }
);

const emptyGroupName = 'none';
function groupCategoriesByParent(
  categories: Nullable<Category[]>,
  facetData: Record<number, number>
) {
  if (isEmpty(categories)) {
    return {};
  }

  const groupedCategories = categories
    .map((category) => {
      let label = category.i18nName;
      if (facetData) {
        label += ' (' + (facetData[category.id] ?? 0) + ')';
      }
      const value = category.id;
      const group = category.parent?.i18nName || emptyGroupName;
      return { label, value, group };
    })
    .filter((category) => category.label && category.value && category.group)
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .reduce<{
      [key: string]: { label: string; value: string; group?: string }[];
    }>((acc, category) => {
      if (category.group) {
        if (!acc[category.group]) {
          acc[category.group] = [];
        }
        acc[category.group].push(category);
      } else {
        if (!acc[emptyGroupName]) {
          acc[emptyGroupName] = [];
        }
        acc[emptyGroupName].push(category);
      }
      return acc;
    }, {});

  return groupedCategories;
}

const { durationStats, lengthStats } = await fetchTourStats(widgetConfig);

const durationMin = computed(() => toValue(durationStats)?.intMin ?? 0);
const durationMax = computed(() => toValue(durationStats)?.intMax ?? 36 * 60);

const lengthMin = computed(() =>
  Math.floor(toValue(lengthStats)?.floatMin ?? 0)
);
const lengthMax = computed(() =>
  Math.ceil(toValue(lengthStats)?.floatMax ?? 100)
);

watch(
  durationMax,
  (newValue) => {
    if (selectedItems.value.duration[1] === 0) {
      selectedItems.value.duration[1] = newValue;
    }
  },
  { immediate: true }
);
watch(
  lengthMax,
  (newValue) => {
    if (selectedItems.value.length[1] === 0) {
      selectedItems.value.length[1] = newValue;
    }
  },
  { immediate: true }
);
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
