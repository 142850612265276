export const mapIsoCodeToFlagIcon = (isoCode: string): string | undefined => {
  switch (isoCode) {
    case 'de':
      return 'openmoji:flag-germany';
    case 'en':
      return 'openmoji:flag-united-kingdom';
    case 'nl':
      return 'openmoji:flag-netherlands';
    default:
      return undefined;
  }
};
