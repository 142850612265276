<template>
  <div>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <Title v-if="appConfig.appType === WhitelabelAppType.PORTAL">{{
          title
        }}</Title>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
        <component :is="'style'">{{ brandingStyles }}</component>
      </Head>
      <Body>
        <div ref="relationContainer" class="relationContainer">
          <div
            class="modalBackdrop"
            :class="{ modalOpened: globalStore.state.modalState === 'open' }"
          ></div>

          <Pageheader :config="pageHeaderConfig">
            <PageheaderSearch />
          </Pageheader>

          <main class="v-el-last pageMain">
            <slot />
          </main>

          <ArticleTaglistWrapped
            v-if="
              whlPageType != WhlPageType.Detail &&
              whlPageType != WhlPageType.Bookmarks &&
              widgetConfigTypeDef?.showSearchFilter &&
              widgetConfigTypeDef?.filterEnabled &&
              widgetConfigTypeDef?.categoryFilterEnabled &&
              !isEmpty(taglist.items)
            "
            :content="taglist"
          />

          <span class="global-placeholder-el-large"></span>

          <PageFooter
            v-if="appConfig.appType === WhitelabelAppType.PORTAL"
            :config="pageFooterConfig"
            :content="pageFooterContent"
          />
        </div>
      </Body>
    </Html>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import type { Taglist } from '@components/Article/Taglist/models';
import type {
  LanguageOption,
  MetaNavItem,
  Content as PageFooterContent,
  Config as PageFooterConfig,
} from '@components/PageFooter/models';
import type { Config as PageheaderConfig } from '@components/Pageheader/models';
import type { CategoryFilter } from '@components/Pageheader/Search/models';
import type { RawLinkTargetFragment } from '@gql/fragments/__generated/RawLinkTarget';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import type { RawWidgetConfigPoiDefFragment } from '@gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { RawWidgetConfigTourDefFragment } from '@gql/fragments/__generated/RawWidgetConfigTourDef';
import type { AddressbaseFilter, Category, EventFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhitelabelAppType } from '@models/WhitelabelAppType';
import { WhlModuleType } from '@models/WhlModuleType';
import { WhlPageType } from '@models/WhlPageType';
import { useGlobalStore } from '../stores/globalStore';
import isEmpty from '@utils/isEmpty';

const { t } = useI18n();
const searchStore = useSearchStore();

const appConfig = useWhlAppConfig();
const instanceConfig = useWhlInstanceConfig();
const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(widgetConfig);
const route = useRoute();
const globalStore = useGlobalStore();

const relationContainer = ref(null);
const { width } = useElementSize(relationContainer);
globalStore.setContainerWidth(toValue(width));
watch(width, () => globalStore.setContainerWidth(toValue(width)));

const whlModuleType = useWhlModuleType();
const whlPageType = useWhlPageType();

const brandingIdentifier = computed(
  (): string =>
    instanceConfig.value?.brandingAlias ??
    instanceConfig.value?.instanceId ??
    'base'
);

// use branding from widget config
const brandingStyles = computed(() => {
  if (appConfig.appType === WhitelabelAppType.PORTAL) {
    return ':root { ' + (widgetConfig.value?.styling ?? '') + ' }';
  } else {
    return ':host { ' + (widgetConfig.value?.styling ?? '') + ' }';
  }
});

const whitelabelBaseUrl =
  window?.__IMXPLATFORM__WIDGET__?.widgetBaseUrl ??
  '/'; /* safe default for portal */

useBrandingStyles(whitelabelBaseUrl, toValue(brandingIdentifier));

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

const title = t((route.meta.title as string) ?? 'app.title.dyn');

const metaNav = computed(() => {
  const links: MetaNavItem[] = (widgetConfig.value?.footerLinkTargets ?? [])
    .map((link: RawLinkTargetFragment): MetaNavItem => {
      return {
        href: link.targetUrl ?? '',
        text: link.label ?? '',
        target: link.openInNewPage ? '_blank' : '_self',
      };
    })
    .filter((value: MetaNavItem) => !isEmpty(value));

  if (!isEmpty(widgetConfig.value?.imprintUrl)) {
    links.push({
      href: widgetConfig.value?.imprintUrl ?? '',
      text: t('footer.metaNav.imprint'),
      target: '_blank',
    });
  }
  if (!isEmpty(widgetConfig.value?.privacyUrl)) {
    links.push({
      href: widgetConfig.value?.privacyUrl ?? '',
      text: t('footer.metaNav.privacy'),
      target: '_blank',
    });
  }
  return links;
});

const languageOptions = computed((): LanguageOption[] => {
  return (instanceConfig.value?.enabledLanguages ?? []).map(
    (isoLang: string): LanguageOption => {
      return {
        label: t('locales.dyn.' + isoLang),
        value: isoLang,
        icon: mapIsoCodeToFlagIcon(isoLang),
      };
    }
  );
});

const lowerCasedWhlModuleType = computed(() =>
  toValue(whlModuleType)?.toLowerCase()
);

const pageHeaderConfig: Ref<PageheaderConfig> = computed(
  (): PageheaderConfig => {
    return {
      title: {
        title:
          whlPageType.value === WhlPageType.Bookmarks
            ? t('layouts.default.pageHeaderConfig.title.bookmarks')
            : t('pageHeader.title.title.dyn.' + lowerCasedWhlModuleType.value),
        subtitle:
          whlPageType.value === WhlPageType.Bookmarks
            ? ''
            : (widgetConfig.value?.locationLabel ?? ''),
      },
      hide: {
        search: !widgetConfigTypeDef.value?.showSearchFilter,
        functions: !widgetConfig.value?.quickAccessList,
        title: false,
        logo: !widgetConfig.value?.showLogo,
      },
      useFulltextsearch: true,
      useDatepicker: true,
    };
  }
);

const pageFooterContent: Ref<PageFooterContent> = computed(
  (): PageFooterContent => {
    const footer: PageFooterContent = {
      address: instanceConfig.value?.footerContact,
      metaNav: metaNav.value,
      languageOptions: languageOptions.value,
    };

    const partnerClientEventUrl = (
      widgetConfigTypeDef.value as { partnerClientUrl?: string }
    )?.partnerClientUrl;
    if (!isEmpty(partnerClientEventUrl)) {
      return {
        ...footer,
        addRecord: {
          title: t(
            'footer.metaNav.addRecord.dyn.' +
              lowerCasedWhlModuleType.value +
              '.title'
          ),
          text: t(
            'footer.metaNav.addRecord.dyn.' +
              lowerCasedWhlModuleType.value +
              '.text'
          ),
          link: {
            href: partnerClientEventUrl ?? '',
            text: t(
              'footer.metaNav.addRecord.dyn.' +
                lowerCasedWhlModuleType.value +
                '.link'
            ),
            target: '_blank',
          },
        },
      };
    }

    return footer;
  }
);

const pageFooterConfig: Ref<PageFooterConfig> = computed(
  (): PageFooterConfig => {
    return {
      hide: {
        logo: !widgetConfig.value?.showFooterLogo,
      },
    };
  }
);

const filter = computed((): EventFilter | AddressbaseFilter => {
  const whlModuleTypeValue = toValue(whlModuleType);
  if (!whlModuleTypeValue) {
    return {};
  }

  switch (whlModuleTypeValue) {
    case WhlModuleType.Event:
      return toValue(searchStore.eventUserFilter);
    case WhlModuleType.Poi:
      return toValue(searchStore.poiUserFilter);
    case WhlModuleType.Tour:
      return toValue(searchStore.tourUserFilter);
  }

  return {};
});

// get facets from backend
const { facets } = (() => {
  if (whlModuleType.value === WhlModuleType.Event) {
    const baseFilter = buildEventBaseFilter(widgetConfig);
    return fetchEventFacets(baseFilter, filter, widgetConfig);
  } else if (whlModuleType.value === WhlModuleType.Poi) {
    const baseFilter = buildPoiBaseFilter(widgetConfig);
    return fetchPoiFacets(baseFilter, filter, widgetConfig);
  } else if (whlModuleType.value === WhlModuleType.Tour) {
    const baseFilter = buildTourBaseFilter(widgetConfig);
    return fetchPoiFacets(baseFilter, filter, widgetConfig);
  }
  return { facets: null };
})();

const taglist = computed((): Taglist => {
  // get Union of categories from config and filter out already selected categories
  const categories = computed(() =>
    constructCategoryFilterObject(
      toValue(whlModuleType),
      toValue(widgetConfigTypeDef)
    ).filter(
      (category) =>
        category.id && !searchStore.state.categories.includes(category.id)
    )
  );

  const items = categories.value
    .map((category) => {
      const count = computed(
        () =>
          facets?.value?.find(
            (facet) => (facet.data as Category).id === category.id
          )?.count ?? 0
      );

      return {
        text: category.i18nName!,
        id: category.id!,
        count: count.value,
      };
    })
    .filter((item) => item.count > 0);

  return {
    title: t('event.search.taglist.title.dyn.' + lowerCasedWhlModuleType.value),
    items: items,
  };
});

function constructCategoryFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): CategoryFilter[] {
  if (isEmpty(module) || isEmpty(widgetConfigTypeDef)) {
    return [];
  }

  let categories: CategoryFilter[] = [];

  if (module === WhlModuleType.Poi) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).categoryFilterProductlines
        ?.filter((productline) => productline.id && productline.i18nName)
        .map((productline) => ({
          id: productline.id!,
          i18nName: productline.i18nName!,
        })) ?? [];
  } else if (module === WhlModuleType.Event) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).categoryFilterCategories
        ?.filter((category) => category.id && category.i18nName)
        .map((category) => ({
          id: category.id!,
          i18nName: category.i18nName!,
        })) ?? [];
  } else if (module === WhlModuleType.Tour) {
    categories =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).activityFilterTourCategories
        ?.filter((category) => category.id && category.i18nName)
        .map((category) => ({
          id: category.id!,
          i18nName: category.i18nName!,
        })) ?? [];
  }
  return categories;
}
</script>
