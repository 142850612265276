<template>
  <div class="additional">
    <InputCalendarAdditionalDayTime
      v-model="model.dayTime"
      :label="t('components.input.calendar.additional.timeofday')"
      :items="dayTime"
      name="dayTime"
    />

    <InputCalendarAdditionalCategories
      v-model="model.categories"
      :label="t('components.input.calendar.additional.categories')"
      :items="categories"
      name="categories"
    />
  </div>
</template>

<script lang="ts" setup>
import type { WidgetConfigEventDef } from '@gql/schema';
import { WhlModuleType } from '@models/WhlModuleType';

const { t } = useI18n();
const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(
  widgetConfig
) as Ref<WidgetConfigEventDef>;

const model = defineModel<{ daytime: string[]; categories: string[] }>({
  default: { dayTime: [], categories: [] },
});

const dayTime = [
  {
    label: t('components.input.calendar.additional.daytime.morning'),
    icon: 'mdi:sunrise',
    value: 'morning',
  },
  {
    label: t('components.input.calendar.additional.daytime.afternoon'),
    icon: 'material-symbols:clear-day',
    value: 'afternoon',
  },
  {
    label: t('components.input.calendar.additional.daytime.evening'),
    icon: 'mdi:weather-sunset-down',
    value: 'evening',
  },
];

const categories = computed(() => {
  const widgetConfigTypeDefValue = toValue(widgetConfigTypeDef);

  const items = [];

  if (widgetConfigTypeDefValue.type === WhlModuleType.Event) {
    if (widgetConfigTypeDefValue.oneOffEventsEnabled) {
      items.push({ label: t('filter.events.onlyoneoffs'), value: 'single' });
    }
    if (widgetConfigTypeDefValue.freeEntryEventsEnabled) {
      items.push({
        label: t('filter.events.onlyfreeadmission'),
        value: 'free-of-charge',
      });
    }
  }

  return items;
});
</script>

<style src="./Additional.scss" scoped lang="scss"></style>
