import { useQuery } from '@urql/vue';
import {
  EventCategoriesFacetsDocument,
  type EventCategoriesFacetsQuery,
  type EventCategoriesFacetsQueryVariables,
} from '@gql/queries/__generated/EventCategoriesFacets';
import type { EventFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';

export default (
  baseFilter: Ref<Nullable<EventFilter>>,
  userFilter: Ref<Nullable<EventFilter>>,
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>
) => {
  const filter = computed(() => {
    const userCategories = userFilter.value?.categories?.oneOf;
    const baseCategories = baseFilter.value?.categories;
    const mergedCategories = {
      oneOf: [...(baseCategories?.oneOf || [])],
      allOf: [...(baseCategories?.allOf || [])],
      noneOf: [...(baseCategories?.noneOf || []), ...(userCategories || [])],
    };

    return {
      and: [
        toValue(baseFilter),
        {
          ...toValue(userFilter),
          categories: !isEmpty(mergedCategories)
            ? { ...mergedCategories }
            : undefined,
        },
      ],
    };
  });

  const shouldPause = computed(() => isEmpty(userFilter));

  const { fetching, data, error } = useQuery<
    EventCategoriesFacetsQuery,
    EventCategoriesFacetsQueryVariables
  >({
    query: EventCategoriesFacetsDocument,
    variables: {
      filter: toValue(filter),
      appearance: toValue(buildAppearanceFilterFromWidgetConfig(widgetConfig)),
      pause: shouldPause,
    },
  });

  const facets = computed(() => {
    return data.value?.events?.facets.fields?.at(0)?.facets;
  });

  return { fetching, error, facets };
};
