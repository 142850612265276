<template>
  <figure class="fig">
    <FigProxyPicture
      v-if="deeplink"
      :src="deeplink!"
      :alt="pooledMedium?.altText"
      :title="pooledMedium?.title"
      :breakpoints="[
        {
          media: '(min-width: 651px)',
          dimensions: '284x145',
        },
        {
          media: '(max-width: 650px) and (min-width: 429px)',
          dimensions: '235x120',
        },
        { media: '(max-width: 428px)', dimensions: '169x86' },
      ]"
    />
    <FigFallback v-else />
    <FigCaption
      v-if="pooledMedium?.copyright"
      :content="pooledMedium.copyright"
    />
  </figure>
</template>

<script lang="ts" setup>
import type { BaseImage } from '@models/BaseImage';

const props = defineProps<{
  content: BaseImage;
}>();

const deeplink = computed(() => props.content.deeplink);
const pooledMedium = computed(() => props.content.pooledMedium);
</script>

<style src="./Fig.scss" scoped lang="scss"></style>
